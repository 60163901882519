import { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { Toast } from 'primereact/toast';
import { RefObject } from 'react';

import { FileTypes } from '../../enums/files';
import { MyReportsConfig } from '../../types/api/clients';
import {
  BatchOrderPdfStickerQueryParams,
  OrderPdfStickerQueryParams,
} from '../../types/reports/sticker';
import { WithTemplate, XOR } from '../../types/util';
import api from '../../utils/api';
import { specialCharsRegex } from '../constants/regex';
import { queryString } from './http';
import { errorToast, infoToast } from './primereact';

function saveFile(data: any, name: string, type: string): void {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);

  document.body.appendChild(link);
  link.click();
  link.remove();
}

function fileDownloadError(toastRef: RefObject<Toast> | undefined): void {
  errorToast(
    toastRef,
    i18next.t('Error'),
    i18next.t(
      'There was an error processing your request. Your file could not be downloaded. Please try again later.'
    )
  );
}

// Note: these save[Extension]File functions are somewhat obsolete.
//  You may want to use downloadFile instead.
export function saveCsvFile(data: any, name: string): void {
  saveFile(data, name, FileTypes.CSV);
}

export function saveXlsxFile(data: any, name: string): void {
  saveFile(data, name, FileTypes.XLSX);
}

export function savePdfFile(data: any, name: string): void {
  saveFile(data, name, FileTypes.PDF);
}

export function saveJsonFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.JSON);
}

export function saveXmlFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.XML);
}

export function printPDF(
  axiosConfig: string,
  toastRef: RefObject<Toast> | undefined
): any {
  if (toastRef?.current) {
    infoToast(
      toastRef,
      i18next.t('Loading...'),
      i18next.t('Your file is being processed and will be ready for print.'),
      { life: 2500 }
    );
  }
  return api({ responseType: 'arraybuffer', url: axiosConfig }).then((res) => {
    const blob = new Blob([res.data], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  });
}

// Prefer using this function for file downloads
export function downloadFile(
  axiosConfig: string | AxiosRequestConfig,
  name: string,
  type: FileTypes,
  toastRef: RefObject<Toast> | undefined,
  responseModifier?: (response: any) => any
): void {
  if (toastRef?.current) {
    infoToast(
      toastRef,
      i18next.t('Downloading...'),
      i18next.t(
        'Your file is being processed and will start downloading soon.'
      ),
      { life: 2500 }
    );
  }

  api({
    responseType: responseModifier === undefined ? 'arraybuffer' : undefined,
    ...(typeof axiosConfig === 'string'
      ? {
          url: axiosConfig,
        }
      : axiosConfig),
  })
    .then((res) => {
      if (typeof responseModifier === 'function') {
        return responseModifier(res.data);
      }

      return res.data;
    })
    .then((data) => {
      const extension = getFileExtension(type);
      saveFile(data, name + '.' + extension, type);
    })
    .catch(fileDownloadError);
}

export async function getParamTypeDownload(
  loggedUser: any,
  selected: any,
  isMultiPrint: boolean,
  typePrint: any
) {
  let typeParam;
  if (!isMultiPrint) {
    // if selected single order
    if (
      selected?.klient_od_id > 1 &&
      selected?.klient_od_id !== loggedUser.client_id
    ) {
      // if senderId is not dummy and sender is not logged customer
      const sender = await api(`/clients/${selected.klient_od_id}/reports`);
      typeParam = sender?.data[typePrint!];
    } else {
      const orderer = await api(`/clients/me/reports`);
      typeParam = orderer?.data[typePrint!];
    }
  } else {
    // check all senderId are equal
    const allEqual = selected?.every(
      (id: any) => id.klient_od_id === selected[0].klient_od_id
    );
    // if senders is not dummy and all senders are the same, take config report from sender
    if (selected[0].klient_od_id > 1 && allEqual) {
      const orderer = await api(`/clients/${selected[0].klient_od_id}/reports`);
      typeParam = orderer?.data[typePrint!];
    } else {
      const orderer = await api(`/clients/me/reports`);
      typeParam = orderer?.data[typePrint!];
    }
  }

  return typeParam;
}

export async function printAddressOrSticker(
  queryParams: any,
  selection: any,
  isMultiPrint: boolean,
  typePrint: 'AddressBook' | 'Sticker',
  loggedUser: any,
  toastRef: RefObject<Toast> | undefined
) {
  const typePrintURL = typePrint === 'AddressBook' ? 'address-book' : 'sticker';
  const template = await getParamTypeDownload(
    loggedUser,
    selection,
    isMultiPrint,
    typePrint
  ); // AddressBook param

  const queryStr = queryString({
    ...queryParams,
    template, // send name param template because of URL pathname
  });

  printPDF(
    `${process.env.REACT_APP_REPORT_URL}/${typePrintURL}/pdf${queryStr}`,
    toastRef
  ).then((data: any) => {
    window.open(data, '_blank')?.print();
    setTimeout(() => {
      window.close();
    }, 1000);
  });
}

export async function printSpecification(
  queryParams: any,
  toastRef: RefObject<Toast> | undefined
) {
  const template = 'basic';
  const queryStr = queryString({
    ...queryParams,
    template, // only basic for print specification
  });

  printPDF(
    `${process.env.REACT_APP_REPORT_URL}/specification/pdf${queryStr}`,
    toastRef
  ).then((data: any) => {
    window.open(data, '_blank')?.print();
    setTimeout(() => {
      window.close();
    }, 1000);
  });
}

export function downloadAddressBook(
  queryParams: object,
  name: string,
  type: FileTypes,
  toastRef: RefObject<Toast> | undefined
): void {
  api
    .get('/clients/me/reports')
    .then((res: AxiosResponse<MyReportsConfig>) => {
      const template = res.data.AddressBook;

      const queryStr = queryString({
        ...queryParams,
        template,
      });

      downloadFile(
        `${process.env.REACT_APP_REPORT_URL}/address-book/pdf${queryStr}`,
        name,
        type,
        toastRef
      );
    })
    .catch(fileDownloadError);
}

export function downloadSticker(
  queryParams: XOR<OrderPdfStickerQueryParams, BatchOrderPdfStickerQueryParams>,
  name: string,
  type: FileTypes,
  toastRef: RefObject<Toast> | undefined
): void {
  api
    .get('/clients/me/reports')
    .then((res: AxiosResponse<MyReportsConfig>) => {
      const template = res.data.Sticker;

      const queryStr = queryString<WithTemplate<typeof queryParams>>({
        ...queryParams,
        template,
      });
      downloadFile(
        `${process.env.REACT_APP_REPORT_URL}/sticker/pdf${queryStr}`,
        name,
        type,
        toastRef
      );
    })
    .catch(fileDownloadError);
}

export function getFileExtension(type: FileTypes): string {
  switch (type) {
    case FileTypes.CSV:
      return 'csv';

    case FileTypes.JSON:
      return 'json';

    case FileTypes.PDF:
      return 'pdf';

    case FileTypes.XLSX:
      return 'xlsx';

    case FileTypes.TXT:
      return 'txt';

    case FileTypes.XML:
      return 'xml';
  }
}

/**
 * Note: File extension is not included.
 * For file extensions, please consider using `getFileExtension`.
 */
export function getFileName(
  page: string,
  identifier: string | string[] | undefined,
  timestamp: boolean = false
): string {
  const processedIdentifiers =
    identifier &&
    (typeof identifier === 'string' ? [identifier] : identifier)
      .map((i) => i.replace(specialCharsRegex, '-'))
      .map((i) => i.replace(/-{2,}/g, '-'))
      .join('_')
      .replace(/_{2,}/g, '_');

  return (
    i18next.t('Accura') +
    '_' +
    page +
    (processedIdentifiers?.length ? '_' + processedIdentifiers : '') +
    (timestamp ? '_' + dayjs().format('YYYYMMDDHHmmss') : '')
  );
}

// -------------------------------- //
// ------- !!! NOT USED !!! ------- //
// -------------------------------- //
//  If the CSV contains only one column, we'll try to return the best suitable delimiter.
//  If all fails, chances are the CSV file is seriously bad, so we return the fallback delimiter.
export function detectCsvDelimiter(data: string): string {
  const potentialDelimiters = [',', '\t', ';', '|'];
  const fallbackDelimiter = ',';
  let delimitersForWhichColumnLengthIsOne: string[] = [];

  const rows = data.trim().split('\n');

  const delimiter = potentialDelimiters.find((d) => {
    const expectedColumnsLength = rows[0].split(d).length;

    if (expectedColumnsLength === 1) {
      delimitersForWhichColumnLengthIsOne.push(d);

      return false;
    }

    for (const row of rows) {
      if (row.split(d).length !== expectedColumnsLength) {
        return false;
      }
    }

    return true;
  });

  if (delimiter) {
    return delimiter;
  }

  if (delimitersForWhichColumnLengthIsOne.length === 0) {
    return fallbackDelimiter;
  }

  return (
    potentialDelimiters.find((d) => {
      for (const row of rows) {
        if (row.split(d).length !== 1) {
          return false;
        }
      }

      return true;
    }) ?? fallbackDelimiter
  );
}

function csvDelimiterToRegex(delimiter: string): string {
  switch (delimiter) {
    case '\t':
      return '\\t';

    case '|':
      return '\\|';

    default:
      return delimiter;
  }
}

function removeEmptyRowsFromCsvContent(
  content: string,
  delimiter: string
): string {
  const pattern = new RegExp(
    '\\n' + csvDelimiterToRegex(delimiter) + '+',
    'gm'
  );

  return content
    .replace(/^("\s*"\s*)+$/gm, '')
    .replace(pattern, '')
    .split('\n')
    .filter((row) => !!row.trim())
    .join('\n');
}

function removeNewLineFromCsvCells(content: string): string {
  return content.replace(/(\s)*(\r)?\n(\s)*(?!")/gm, ' ');
}

function trimCsvCells(content: string, delimiter: string): string {
  return content
    .split('\n')
    .map((row) =>
      row
        .split(delimiter)
        .map(
          (cell) =>
            '"' +
            cell
              .trim()
              // Unwrap cell from " or ' characters
              .replace(/^"(.+)"$/, '$1')
              .replace(/^'(.+)'$/, '$1')
              // Just in case trim
              .trim() +
            '"'
        )
        .join(delimiter)
    )
    .join('\n');
}

export function cleanUpCsv(content: string, delimiter: string): string {
  return trimCsvCells(
    removeNewLineFromCsvCells(
      removeEmptyRowsFromCsvContent(content, delimiter)
    ),
    delimiter
  );
}

export function saveTxtFile(data: string, name: string): void {
  saveFile(data, name, FileTypes.TXT);
}
