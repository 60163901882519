import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { OrderCollection, OrderResource } from '../../../../types/api/orders';
import { LabelValue } from '../../../../types/options';
import { debounceTimeout } from '../../../../utils/constants/misc';
import {
  Action,
  FallbackValues,
  FormFields,
  SpecialProduct,
} from './CreateEditRecreate.functions';

type Return = {
  action: Action;
  relatedOrderOptions: LabelValue[];
  specialProductOptions: LabelValue[];
  returnDocumentOptionsData: LabelValue[];
  isSpecialProductDataLoading: boolean;
  isReturnDocumentOptionsDataLoading: boolean;
  isRelatedOrderOptionsDataLoading: boolean;
};

function useOrderSettingsStep(
  methods: UseFormReturn<FormFields>,
  defaultValues: FormFields,
  action: Action
): Return {
  const { setValue, control } = methods;

  const reference_id = useWatch<FormFields, 'reference_id'>({
    name: 'reference_id',
    control,
  });

  const related_order_filter = useWatch<FormFields, 'related_order_filter'>({
    name: 'related_order_filter',
    control,
  });

  const sirina = useWatch<FormFields, 'sirina'>({
    name: 'sirina',
    control,
  });

  const visina = useWatch<FormFields, 'visina'>({
    name: 'visina',
    control,
  });

  const dolzina = useWatch<FormFields, 'dolzina'>({
    name: 'dolzina',
    control,
  });

  const debounced_related_order_filter = useDebounce(
    related_order_filter,
    debounceTimeout
  );

  const {
    data: relatedOrderOptionsData,
    isLoading: isRelatedOrderOptionsDataLoading,
  } = useAxios<OrderCollection>(
    `/orders?serial=${debounced_related_order_filter}`,
    { skipWhen: !debounced_related_order_filter }
  );

  const relatedOrderOptions = useMemo<LabelValue[]>(
    () =>
      relatedOrderOptionsData?.data.map((o) => ({
        label: o.seriski_broj,
        value: String(o.id),
      })) ?? [],
    [relatedOrderOptionsData]
  );

  const { data: relatedOrder } = useAxios<OrderResource>(
    `/orders/${reference_id}`,
    {
      skipWhen: !reference_id || !!related_order_filter,
    }
  );

  const { data: specialProductData, isLoading: isSpecialProductDataLoading } =
    useAxios<SpecialProduct[]>('/specialized_products');

  const specialProductOptions = useMemo<any[]>(
    () =>
      specialProductData?.map((product) => ({
        label: product.name,
        value: String(product.id),
      })) ?? [],
    [specialProductData]
  );

  const {
    data: returnDocumentData,
    isLoading: isReturnDocumentOptionsDataLoading,
  } = useAxios<SpecialProduct[]>('/return-document-types');

  const returnDocumentOptionsData = useMemo<any[]>(
    () =>
      returnDocumentData?.map((product) => ({
        label: product.name,
        value: product.id,
      })) ?? [],
    [returnDocumentData]
  );

  const prevRelatedOrder = usePrevious(relatedOrder);

  useEffect(() => {
    if (!relatedOrder || prevRelatedOrder || related_order_filter) {
      return;
    }

    setValue('related_order_filter', relatedOrder.seriski_broj);
  }, [prevRelatedOrder, relatedOrder, related_order_filter, setValue]);

  const debounced_sirina = useDebounce(sirina, debounceTimeout);
  const debounced_visina = useDebounce(visina, debounceTimeout);
  const debounced_dolzina = useDebounce(dolzina, debounceTimeout);

  useEffect(() => {
    const volume =
      parseFloat(debounced_sirina) *
      parseFloat(debounced_visina) *
      parseFloat(debounced_dolzina);

    // TODO: remove defaultValues fallback after API supports dimensions
    setValue(
      'volumen',
      !isNaN(volume)
        ? volume === 0
          ? defaultValues.volumen
          : volume.toFixed(2)
        : FallbackValues.Volume
    );
  }, [
    debounced_dolzina,
    debounced_sirina,
    debounced_visina,
    defaultValues.volumen,
    setValue,
  ]);

  return {
    action,
    relatedOrderOptions,
    specialProductOptions,
    isSpecialProductDataLoading,
    returnDocumentOptionsData,
    isReturnDocumentOptionsDataLoading,
    isRelatedOrderOptionsDataLoading,
  };
}

export default useOrderSettingsStep;
