import { useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import usePrevious from '../../../../hooks/usePrevious';
import {
  ClientResource,
  ClientSubsidiaryCollection,
} from '../../../../types/api/clients';
import { OrderResource } from '../../../../types/api/orders';
import { ReduxState } from '../../../../types/redux';
import { isStreetFreeInputAllowed } from '../../../../utils/constants/misc';
import {
  Action,
  CustomerRole,
  FormFields,
  getDefaultValues,
} from './CreateEditRecreate.functions';

function useCustomerRoleWatcher(
  methods: UseFormReturn<FormFields>,
  order: OrderResource | undefined,
  user: ClientResource | undefined,
  subsidiaries: ClientSubsidiaryCollection | undefined,
  action: Action
) {
  const isCreating = action === 'create';

  const userClientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user.client_id
  );

  const { control, setValue } = methods;

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
    control,
  });

  const klient_od_id = useWatch<FormFields, 'klient_od_id'>({
    name: 'klient_od_id',
    control,
  });

  const klient_do_id = useWatch<FormFields, 'klient_do_id'>({
    name: 'klient_do_id',
    control,
  });

  const defaultValues = useMemo(
    () => getDefaultValues(userClientId, order, subsidiaries),
    [order, subsidiaries, userClientId]
  );

  const emptyDefaultValues = useMemo(
    () => getDefaultValues(userClientId, undefined, undefined),
    [userClientId]
  );

  const previousUser = usePrevious(user);
  const previousDefaultValues = usePrevious(defaultValues);
  const previousEmptyDefaultValues = usePrevious(emptyDefaultValues);
  const previous_customer_role = usePrevious(customer_role);

  const returnValue = useCallback(() => {
    if (
      customer_role === previous_customer_role &&
      defaultValues === previousDefaultValues &&
      emptyDefaultValues === previousEmptyDefaultValues &&
      user === previousUser
    ) {
      return;
    }

    function resetSenderFields(values: FormFields) {
      setValue('klient_od_id', values.klient_od_id);
      setValue('klient_od_ime', values.klient_od_ime);
      setValue('mobilen_od', values.mobilen_od);
      setValue('telefon_od', values.telefon_od);
      setValue('mesto_od_id', values.mesto_od_id);
      setValue('mesto_od_ime', values.mesto_od_ime);
      setValue('ulica_od_id', values.ulica_od_id);
      setValue('ulica_od_ime', values.ulica_od_ime);
      setValue('adresa_od', values.adresa_od);
      setValue('broj_od', values.broj_od);
      setValue('vlez_od', values.vlez_od);
      setValue('stan_od', values.stan_od);
      setValue('pickup_location_type_id', values.pickup_location_type_id);
      setValue('hub_od_id', values.hub_od_id);
      setValue('senderInvoice', values?.senderInvoice);
    }

    function resetRecipientFields(values: FormFields) {
      setValue('klient_do_id', values.klient_do_id);
      setValue('klient_do_ime', values.klient_do_ime);
      setValue('mobilen_do', values.mobilen_do);
      setValue('telefon_do', values.telefon_do);
      setValue('mesto_do_id', values.mesto_do_id);
      setValue('mesto_do_ime', values.mesto_do_ime);
      setValue('ulica_do_id', values.ulica_do_id);
      setValue('ulica_do_ime', values.ulica_do_ime);
      setValue('adresa_do', values.adresa_do);
      setValue('broj_do', values.broj_do);
      setValue('vlez_do', values.vlez_do);
      setValue('stan_do', values.stan_do);
      setValue('delivery_location_type_id', values.delivery_location_type_id);
      setValue('hub_do_id', values.hub_do_id);
      setValue('recipientInvoice', values?.recipientInvoice);
    }

    if (customer_role === CustomerRole.Sender) {
      if (previous_customer_role !== CustomerRole.Orderer) {
        resetRecipientFields(
          klient_do_id === userClientId ||
            userClientId === defaultValues.klient_do_id
            ? emptyDefaultValues
            : defaultValues
        );
      }

      setValue(
        'klient_od_id',
        isCreating
          ? Number(user?.id) ?? defaultValues.klient_od_id
          : defaultValues.klient_od_id
      );

      setValue(
        'klient_od_ime',
        isCreating
          ? user?.ime ?? defaultValues.klient_od_ime
          : defaultValues.klient_od_ime
      );

      setValue(
        'mobilen_od',
        isCreating
          ? user?.mobilen ?? defaultValues.mobilen_od
          : defaultValues.mobilen_od
      );

      setValue(
        'telefon_od',
        isCreating
          ? user?.telefon ?? defaultValues.telefon_od
          : defaultValues.telefon_od
      );

      setValue(
        'mesto_od_id',
        isCreating
          ? user?.address.placeId ?? defaultValues.mesto_od_id
          : defaultValues.mesto_od_id
      );

      setValue(
        'mesto_od_ime',
        isCreating
          ? user?.address.placeName ?? defaultValues.mesto_od_ime
          : defaultValues.mesto_od_ime
      );

      if (isStreetFreeInputAllowed) {
        setValue(
          'adresa_od',
          isCreating
            ? user?.address.rawName ?? defaultValues.adresa_od
            : defaultValues.adresa_od
        );
      } else {
        setValue(
          'ulica_od_id',
          isCreating
            ? user?.address.streetId ?? defaultValues.ulica_od_id
            : defaultValues.ulica_od_id
        );

        setValue(
          'ulica_od_ime',
          isCreating
            ? user?.address.streetName ?? defaultValues.ulica_od_ime
            : defaultValues.ulica_od_ime
        );

        setValue(
          'broj_od',
          isCreating
            ? user?.address.housenumber ?? defaultValues.broj_od
            : defaultValues.broj_od
        );

        setValue(
          'vlez_od',
          isCreating
            ? user?.address.entrance ?? defaultValues.vlez_od
            : defaultValues.vlez_od
        );

        setValue(
          'stan_od',
          isCreating
            ? user?.address.apartment ?? defaultValues.stan_od
            : defaultValues.stan_od
        );
      }

      setValue(
        'senderInvoice',
        isCreating
          ? user?.faktura ?? defaultValues.senderInvoice
          : defaultValues.senderInvoice
      );
    } else if (customer_role === CustomerRole.Recipient) {
      if (previous_customer_role !== CustomerRole.Orderer) {
        resetSenderFields(
          klient_od_id === userClientId ||
            userClientId === defaultValues.klient_od_id
            ? emptyDefaultValues
            : defaultValues
        );
      }

      setValue(
        'klient_do_id',
        isCreating
          ? Number(user?.id) ?? defaultValues.klient_do_id
          : defaultValues.klient_do_id
      );

      setValue(
        'klient_do_ime',
        isCreating
          ? user?.ime ?? defaultValues.klient_do_ime
          : defaultValues.klient_do_ime
      );

      setValue(
        'mobilen_do',
        isCreating
          ? user?.mobilen ?? defaultValues.mobilen_do
          : defaultValues.mobilen_do
      );

      setValue(
        'telefon_do',
        isCreating
          ? user?.telefon ?? defaultValues.telefon_do
          : defaultValues.telefon_do
      );

      setValue(
        'mesto_do_id',
        isCreating
          ? user?.address.placeId ?? defaultValues.mesto_do_id
          : defaultValues.mesto_do_id
      );

      setValue(
        'mesto_do_ime',
        isCreating
          ? user?.address.placeName ?? defaultValues.mesto_do_ime
          : defaultValues.mesto_do_ime
      );

      if (isStreetFreeInputAllowed) {
        setValue(
          'adresa_do',
          isCreating
            ? user?.address.rawName ?? defaultValues.adresa_do
            : defaultValues.adresa_do
        );
      } else {
        setValue(
          'ulica_do_id',
          isCreating
            ? user?.address.streetId ?? defaultValues.ulica_do_id
            : defaultValues.ulica_do_id
        );

        setValue(
          'ulica_do_ime',
          isCreating
            ? user?.address.streetName ?? defaultValues.ulica_do_ime
            : defaultValues.ulica_do_ime
        );

        setValue(
          'broj_do',
          isCreating
            ? user?.address.housenumber ?? defaultValues.broj_do
            : defaultValues.broj_do
        );

        setValue(
          'vlez_do',
          isCreating
            ? user?.address.housenumber ?? defaultValues.vlez_do
            : defaultValues.vlez_do
        );

        setValue(
          'stan_do',
          isCreating
            ? user?.address.apartment ?? defaultValues.stan_do
            : defaultValues.stan_do
        );
      }

      setValue(
        'recipientInvoice',
        isCreating
          ? user?.faktura ?? defaultValues.recipientInvoice
          : defaultValues.recipientInvoice
      );
    } else {
      if (klient_od_id === userClientId) {
        resetSenderFields(emptyDefaultValues);
      } else if (klient_do_id === userClientId) {
        resetRecipientFields(emptyDefaultValues);
      }
    }
  }, [
    customer_role,
    defaultValues,
    emptyDefaultValues,
    isCreating,
    klient_do_id,
    klient_od_id,
    previousDefaultValues,
    previousEmptyDefaultValues,
    previousUser,
    previous_customer_role,
    setValue,
    user,
    userClientId,
  ]);

  // Whenever the customer role field changes,
  //  reset only the fields related to the new customer role.
  //  In the case of its value being 'orderer', reset both 'sender and 'recipient' fields.
  useEffect(() => {
    returnValue();
  }, [returnValue]);

  return returnValue;
}

export default useCustomerRoleWatcher;
