import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CountryPhone } from '../../../enums/countries';
import { ClientResource } from '../../../types/api/clients';
import { Numeric } from '../../../types/general';
import { isStreetFreeInputAllowed } from '../../../utils/constants/misc';
import { tryString } from '../../../utils/helpers/parse';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
  isValidPhoneNumber,
} from '../../../utils/helpers/phoneNumbers';
import {
  conditionallyRequiredField,
  yupMaxField,
  yupRequiredField,
  yupRequiredPasswordField,
} from '../../../utils/helpers/yup';

export type FormFields = {
  ime: string;
  email: string;
  mobilen: string;
  mobilen_region: CountryPhone;
  telefon: string;
  telefon_region: CountryPhone;
  pravno_lice?: string;
  kontakt_lice?: string;
  danocen_broj?: Numeric;
  smetka?: Numeric | null;
  opstina_id?: Numeric;
  opstina_ime?: string;
  mesto_id: Numeric;
  mesto_ime?: string | null;
  ulica_id: Numeric;
  ulica_ime?: string;
  adresa?: string;
  broj: string;
  new_password?: string | undefined;
  confirm_password?: string | undefined;
  password?: string | undefined;
  change_pass_switch: boolean;
};

export function getInitialValues(data: ClientResource | undefined): FormFields {
  return {
    ime: data?.ime ?? '',
    email: data?.email ?? '',
    mobilen: data?.mobilen ? String(data.mobilen) : '',
    mobilen_region: getPhoneorMobileNumberRegion(
      data?.mobilen ? String(data.mobilen) : ''
    ),
    telefon: data?.telefon ? String(data.telefon) : '',
    telefon_region: getPhoneorMobileNumberRegion(
      data?.telefon ? String(data.telefon) : ''
    ),
    pravno_lice: tryString(data?.pravno_lice),
    kontakt_lice: data?.kontakt_lice ?? '',
    opstina_id: data?.address.municipalityId ?? '',
    opstina_ime: '',
    mesto_id: data?.address.placeId ?? '',
    mesto_ime: '',
    ulica_id: data?.address.streetId ?? '',
    ulica_ime: '',
    adresa: data?.address.rawName ?? '',
    broj: data?.address.housenumber ? String(data.address.housenumber) : '',
    danocen_broj: data?.danocen_broj ?? '',
    smetka: data?.smetka ?? '',
    confirm_password: '',
    new_password: '',
    change_pass_switch: false,
  };
}

export function validationSchema(t: TFunction) {
  return Yup.object().shape({
    ime: Yup.string().required(yupRequiredField(t, t('Name'))),
    email_contact: Yup.string()
      .email()
      .max(254, yupMaxField(t, t('Email (Contact)'), 254)),
    kontakt_lice: Yup.mixed().when('pravno_lice', {
      is: true,
      then: Yup.string().max(45, yupMaxField(t, t('Contact'), 45)),
    }),
    opstina_id: Yup.string().required(yupRequiredField(t, t('Municipality'))),
    mesto_id: Yup.string().required(yupRequiredField(t, t('Place'))),
    ulica_id: conditionallyRequiredField(
      Yup.string().nullable(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    adresa: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Address'))
    ),
    broj: conditionallyRequiredField(
      Yup.string().max(10, ({ max }) => yupMaxField(t, t('Street No.'), max)),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street No.'))
    ),
    mobilen: Yup.string()
      .test(
        'mobilen',
        t('Invalid mobile phone number'),
        (value, context): boolean => {
          if (!value) {
            return true;
          }

          const { mobilen_region } = context.parent;
          return isValidMobileNumber(value, mobilen_region);
        }
      )
      .test(
        'mobilen',
        t('At least mobile or phone number must be filled out'),
        function (value) {
          const { telefon } = this?.parent;
          if (!telefon) {
            return value != null;
          }

          return true;
        }
      ),
    telefon: Yup.string().test(
      'telefon',
      t('Invalid phone number'),
      (value, context): boolean => {
        if (!value) {
          return true;
        }

        const { telefon_region } = context.parent;
        return isValidPhoneNumber(value, telefon_region);
      }
    ),
    new_password: Yup.string().when('change_pass_switch', {
      is: true,
      then: yupRequiredPasswordField(t, 'New Password'),
    }),
    confirm_password: Yup.string().when('change_pass_switch', {
      is: true,
      then: Yup.string()
        .required(yupRequiredField(t, 'Confirm Password'))
        .test(
          'passwords-match',
          t('Passwords must match'),
          (value, { parent }) => value === parent.new_password
        ),
    }),
    change_pass_switch: Yup.boolean(),
  });
}

export function toApiData(formValues: FormFields): Partial<FormFields> {
  let apiData: Partial<FormFields> = {
    ...formValues,
  };

  if (formValues.new_password?.length && formValues.confirm_password?.length) {
    apiData.password = formValues?.confirm_password;
  }

  // Mobile and phone numbers
  if (apiData.telefon) {
    apiData.telefon = formatPhoneNumber(
      apiData.telefon,
      formValues.telefon_region
    );
  }

  if (apiData.mobilen) {
    apiData.mobilen = formatMobileNumber(
      apiData.mobilen,
      formValues.mobilen_region
    );
  }

  // Delete unnecessary data
  delete apiData.danocen_broj;
  delete apiData.smetka;
  delete apiData.new_password;
  delete apiData.confirm_password;
  delete apiData.opstina_id;
  delete apiData.opstina_ime;
  delete apiData.mesto_ime;
  delete apiData.ulica_ime;
  delete apiData.pravno_lice;
  delete apiData.mobilen_region;
  delete apiData.telefon_region;
  delete apiData.change_pass_switch;

  if (!apiData?.kontakt_lice?.length) {
    delete apiData.kontakt_lice;
  }

  return apiData;
}
