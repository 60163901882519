import { createContext } from 'react';

import { ClientAddressCollection } from '../../../../types/api/clientAddress';
import { ClientSubsidiaryCollection } from '../../../../types/api/clients';
import { LabelValue } from '../../../../types/options';
import { Action } from './CreateEditRecreate.functions';

type Context = {
  sender: {
    isPlacesDataLoading: boolean;
    placesOptions: LabelValue[];
    isStreetsDataLoading: boolean;
    streetOptions: LabelValue[];
    isContactDataLoading: boolean;
    contactData: ClientAddressCollection | undefined;
    reloadContactData: () => void;
    subsidiaries: ClientSubsidiaryCollection | undefined;
    hubOptions: LabelValue[];
  };
  recipient: {
    isPlacesDataLoading: boolean;
    placesOptions: LabelValue[];
    isStreetsDataLoading: boolean;
    streetOptions: LabelValue[];
    isContactDataLoading: boolean;
    contactData: ClientAddressCollection | undefined;
    reloadContactData: () => void;
    subsidiaries: ClientSubsidiaryCollection | undefined;
    hubOptions: LabelValue[];
  };
  orderSettings: {
    action: Action;
    relatedOrderOptions: LabelValue[];
    specialProductOptions: LabelValue[];
    returnDocumentOptionsData: LabelValue[];
    isSpecialProductDataLoading: boolean;
    isReturnDocumentOptionsDataLoading: boolean;
    isRelatedOrderOptionsDataLoading: boolean;
  };
};

const StepsContext = createContext<Context>({
  sender: {
    isPlacesDataLoading: false,
    placesOptions: [],
    isStreetsDataLoading: false,
    streetOptions: [],
    isContactDataLoading: false,
    contactData: undefined,
    reloadContactData: () => {},
    subsidiaries: undefined,
    hubOptions: [],
  },
  recipient: {
    isPlacesDataLoading: false,
    placesOptions: [],
    isStreetsDataLoading: false,
    streetOptions: [],
    isContactDataLoading: false,
    contactData: undefined,
    reloadContactData: () => {},
    subsidiaries: undefined,
    hubOptions: [],
  },
  orderSettings: {
    action: 'create',
    relatedOrderOptions: [],
    specialProductOptions: [],
    returnDocumentOptionsData: [],
    isSpecialProductDataLoading: false,
    isReturnDocumentOptionsDataLoading: false,
    isRelatedOrderOptionsDataLoading: false,
  },
});

export default StepsContext;
