import './Notifications.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Skeleton } from 'primereact/skeleton';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import useAxios from '../../../hooks/useAxios';
import usePrevious from '../../../hooks/usePrevious';
import { WithData } from '../../../types/api';
import { ClientResource } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { getChangedValues } from '../../../utils/helpers/object';
import {
  errorToast,
  infoToast,
  successToast,
} from '../../../utils/helpers/primereact';
import { NotificationContent } from './NotificationContent';
import {
  FormValues,
  editDialogApiData,
  getInitialValues,
} from './Notifications.functions';

function Notifications() {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { toastRef } = useContext(ToastContext);

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const {
    data: clientData,
    isLoading,
    reload: reloadClientData,
  } = useAxios<WithData<ClientResource>>('/clients/me');

  const {
    reload: formSubmissionRequest,
    data: editData,
    error: editError,
  } = useAxios();

  function handleEdit() {
    const initialValues = formRef.current?.initialValues;

    const currentValues = formRef?.current?.values;

    const apiData = getChangedValues(currentValues, initialValues);

    // If there are no changes introduced, don't contaminate the API
    if (!Object.keys(apiData).length) {
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    formSubmissionRequest({
      url: `/clients/${clientLoggedIn}/self`,
      data: editDialogApiData(formRef.current?.values),
      method: 'PUT',
    });
  }

  const previousEditData = usePrevious(editData);

  useEffect(() => {
    if (!editData || editData === previousEditData) {
      return;
    }

    successToast(
      toastRef!,
      t('Successfuly updated'),
      t('Notification settings successfuly edited.')
    );

    reloadClientData();
  }, [editData, previousEditData, reloadClientData, t, toastRef]);

  const previousEditError = usePrevious(editError);

  useEffect(() => {
    if (!editError || editError === previousEditError) {
      return;
    }

    errorToast(
      toastRef!,
      t('Error'),
      t('An error occured while editing settings.')
    );
  }, [editError, previousEditError, t, toastRef]);

  const initialValues = useMemo(
    () => getInitialValues(clientData?.data),
    [clientData]
  );

  return (
    <div className="page notifications">
      {isLoading ? (
        <>
          <Skeleton width="100%" height="5rem" className="p-mb-3" />
          <Skeleton width="50%" height="3rem" className="p-mb-3" />
          <Skeleton width="100%" height="20rem" className="p-mb-3" />
          <Skeleton width="100%" height="5rem" className="p-mb-3" />
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleEdit}
          innerRef={formRef}
        >
          <Form>
            <NotificationContent isLoading={isLoading} />
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default Notifications;
