import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { ClientResource } from '../../../../../types/api/clients';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { PlaceResource } from '../../../../../types/api/places';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import { tryString } from '../../../../../utils/helpers/parse';
import {
  conditionallyRequiredField,
  yupRequiredField,
} from '../../../../../utils/helpers/yup';

export type FormValues = {
  list_type: { id: number; name: string } | null;
  municipality: string;
  place: string;
  zip: string;
  street: string;
  address: string;
  house_number: string;
  entrance: string;
  phone: string;
  contact: string;
  flat: string;
  postage: string;
  redemption: string;
  return_document: string;
  insurance: string;
  csv: string;
  delimiter: string;
  import_all_or_none: boolean;
};

export const csvDelimiter = '\t';

export function getInitialValues(
  clientData: ClientResource | undefined,
  placeData: PlaceResource | undefined,
  listType: ImportListTypeResource | undefined
): FormValues {
  return {
    list_type: listType ? { id: listType.id, name: listType.name } : null,
    municipality: tryString(placeData?.opstina_id) ?? '',
    place: String(clientData?.address.placeId) ?? '',
    zip: placeData?.postenski_broj ?? '',
    street: String(clientData?.address.streetId) ?? '',
    address: String(clientData?.address.rawName) ?? '',
    house_number: String(clientData?.address.housenumber) ?? '',
    entrance: String(clientData?.address.entrance) ?? '',
    phone: String(clientData?.mobilen) ?? '',
    contact: String(clientData?.ime) ?? '',
    flat: String(clientData?.address.apartment) ?? '',
    postage: '1',
    redemption: '1',
    return_document: '1',
    insurance: '1',
    csv: '',
    delimiter: '',
    import_all_or_none: true,
  };
}
export function getValidationSchemas(t: TFunction) {
  return {
    listType: Yup.object({
      list_type: Yup.object()
        .nullable()
        .required(yupRequiredField(t, t('List Type'))),
    }),
    addressInfo: Yup.object({
      municipality: Yup.string().required(
        yupRequiredField(t, t('Municipality'))
      ),
      place: Yup.string().required(yupRequiredField(t, t('Place'))),
      street: conditionallyRequiredField(
        Yup.string().nullable(),
        !isStreetFreeInputAllowed,
        yupRequiredField(t, t('Street'))
      ),
      adresa: conditionallyRequiredField(
        Yup.string(),
        isStreetFreeInputAllowed,
        yupRequiredField(t, t('Address'))
      ),
      house_number: conditionallyRequiredField(
        Yup.string(),
        !isStreetFreeInputAllowed,
        yupRequiredField(t, t('Number'))
      ),
    }),
    phone: Yup.string(),
    contact: Yup.string(),
    file: Yup.object({
      csv: Yup.string().required(yupRequiredField(t, t('File'))),
    }),
  };
}
