import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'dayjs/locale/en';
import 'dayjs/locale/mk';
import 'dayjs/locale/sr';
import 'dayjs/locale/sr-cyrl';

import './translations/primereact';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { createBrowserHistory } from 'history';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import ThemeLoader from './features/Themes/ThemeLoader';
import i18n from './i18n';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { tryFloat } from './utils/helpers/parse';

// dayjs
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

// Cypress
if (window.Cypress) {
  window._store = store;
}

// kill all logs in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

// Sentry
const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracingOrigins: [
        'localhost',
        '127.0.0.1',
        process.env.REACT_APP_API_URL!,
        process.env.REACT_APP_REPORT_URL!,
        /^\//,
      ],
    }),
  ],
  tracesSampleRate:
    tryFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ?? 0.2,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeLoader />
        <App history={history} />
      </I18nextProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
