import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../../../../../types/options';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../../../CreateEditRecreate.functions';

function ReturnDocument({
  returnDocumentOptionsData,
}: {
  returnDocumentOptionsData?: LabelValue[];
}): JSX.Element {
  const { t } = useTranslation();

  const povraten_dokument = useWatch<FormFields, 'povraten_dokument'>({
    name: 'povraten_dokument',
  });

  return (
    <>
      <FieldWithErrors
        name="povraten_dokument"
        label={t('Return document')}
        className="data-cy-return_document"
      >
        <Controller
          name="povraten_dokument"
          render={({ field }) => (
            <InputSwitch
              {...field}
              checked={Boolean(field.value)}
              inputId={field.name}
            />
          )}
        />
      </FieldWithErrors>
      {povraten_dokument && (
        <FieldWithErrors
          name="return_document_type_id"
          label={t('Type of return document')}
        >
          <Controller
            name="return_document_type_id"
            render={({ field }) => (
              <Dropdown
                name="return_document_type_id"
                inputId="return_document_type_id"
                options={returnDocumentOptionsData}
                placeholder={t('Select')}
                value={field.value}
                showClear
                onChange={(e) => field.onChange(e.value)}
                className="data-cy-return-document-type-id"
              />
            )}
          />
        </FieldWithErrors>
      )}
    </>
  );
}

export default ReturnDocument;
